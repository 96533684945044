<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.user', 1, true) }}
    </hero-bar>
    <section class="section is-main-section capitalize">
      <b-button tag="router-link"
                :to="{name: 'users.create'}"
                type="is-primary" icon-left="plus">{{ $getTranslation('form.general.add') }} {{ $getTranslation('general.views.user', 0) }}</b-button>
    </section>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced" icon="account-multiple">

        <div v-if="!loading && error === null">

          <!-- TODO - add hasAccess to table action buttons -->
          <app-table
            :columns="columns"
            :page="page"
            :total="total"
            :editRoute="editRoute"
            :deleteRoute="endpoint"
            @onPageChange="onPageChange"
            :pagination-top="true"
            :pagination-bottom="true"
            :hasAccess="hasAccess"
          />

        </div>
        <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>

    </section>
  </div>
</template>

<script>
import { ApiUsers } from '@/api-routes.js'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'home',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },

  data () {
    return {
      endpoint: ApiUsers,
      editRoute: 'users.edit',
      total: 0,
      page: 1,
      // perPage: 15,
      // Table - type is from user table, replace with roles !!

      // TODO: de-duplicate "roles" translation
      // right now this is needed because of the way tables are filled
      // in components/elements/Table.vue
      columns: {'name':'form', /* 'roles': 'form', */ 'username':'form', 'email':'form', 'created':'general', 'updated':'general', /*'deleted':'general'*/}
    }
  },

  computed: {
    // Extend or implement CASL
    hasAccess () {
      return this.isLoggedUser.roles.includes('admin') || this.isLoggedUser.roles.includes('owner')
    }
  },

  methods: {
    getRoles(roles) {
      // console.log('roles?', roles.length)
      if(roles.length === 0) {
        return 'no roles'
      }
      let items = [];
      roles.forEach(function(role) {
        items.push(role.name)
      })
      return items.join(',')
    }
  },

  created () {
    if(this.$route.name !== 'users') {
      this.endpoint += '/userByType/' + this.$route.name
    }
    this.fetchPaginatedItems ()
    // console.log('Users list created', this.$route.name)
  }
}
</script>
